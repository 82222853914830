<template>
    <div class="ad-container flex-shrink-0">
        <div :class="advClassesToAttach">
            <div v-if="isLazyLoaded" class="gptslot infinite-ad"></div>
            <div v-else class="gptslot" :data-adunitid="adUnit"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdUnit',
    props: {
        'ad-unit': {},
        'ad-type': {
            required: true,
        },
        'is-lazy-loaded': {
            default: false,
        }
    },
    computed: {
        advClassesToAttach() {
            // Aggiungiamo ad solo se l'unità è in pagina
            const baseClass = this.isLazyLoaded ? '' : 'ad';

            const mobileClasses = this.isMobile ? 'ad-mobile' : '';

            return [baseClass, mobileClasses, this.adType].join(' ');
        }
    },
    data() {
        return {
            isMobile: this.$store.state.app.isMobile
        }
    }
}
</script>

<style scoped>

.ad-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ad {
    position: relative;
    z-index: 0;
}

.ad::after {
    /* @apply leading-none font-semibold text-gray-500 text-sm flex items-center justify-center; */
    color: rgba(255, 255, 255, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: -1;
    content: "AD";
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.ad-lazy-load {
    margin: auto;
    text-align: center;
}

.ad-rectangle {
    width: 300px;
    height: 300px;
    overflow: hidden;
}

.ad-masthead {
    width: 728px;
    height: 100px;
    position: relative;
    z-index: 0;
}

.ad-masthead.ad-mobile {
    height: 250px;
}


</style>
