<template>
    <div class="relative z-50 sm:fixed sm:top-4 w-full sm:px-4">
        <div class=" mx-auto bg-gradient-to-tl from-menu-bar-gradient-from to-menu-bar-gradient-to
         text-white container max-w-5xl shadow p-4 px-8 sm:rounded">
            <div class="flex space-x-8 items-center justify-between">
                <router-link to="/" v-if="useVueRouter">
                    <AwardsLogo :awards-year="awardsYear"/>
                </router-link>
                <a :href="makeRoute()" v-else>
                    <AwardsLogo :awards-year="awardsYear"/>
                </a>

                <div class="hidden sm:flex sm:flex-1 items-center sm:space-x-4 md:space-x-16 justify-center">

                    <div v-if="pollsActive.indexOf('best_italian_records') !== -1">
                        <router-link
                            v-if="useVueRouter && (pollsActive.indexOf('best_italian_records') !== -1)"
                            :to="{ name: 'best_italian_records' }"
                            class="inline-block text-center leading-none font-bold text-2xl bg-gradient-to-tr transition-all transform hover:scale-110  duration-100"
                        >Miglior Album
                        </router-link>
                        <a :href="makeRoute('/miglior-album-italiano')" v-else
                           class="inline-block text-center leading-none font-bold text-2xl bg-gradient-to-tr transition-all transform hover:scale-110  duration-100">
                            Miglior Album
                        </a>
                    </div>

                    <div v-if="pollsActive.indexOf('best_italian_artist_shows') !== -1">
                        <router-link
                            v-if="useVueRouter"
                            :to="{ name: 'best_italian_artist_shows' }"
                            class="inline-block text-center leading-none font-bold text-2xl transition-all transform hover:scale-105 duration-100"
                        >Miglior Live
                        </router-link>
                        <a :href="makeRoute('/miglior-artista-live-italiano')" v-else
                           class="inline-block text-center leading-none font-bold text-2xl bg-gradient-to-tr transition-all transform hover:scale-110  duration-100">
                            Miglior Live
                        </a>
                    </div>
                </div>

                <div class="flex items-center justify-end" style="width: 105px;">
                    <login-button v-if="!isVoteClosed">
                        <template v-slot:voteButton="{ userLoggedIn, showLogin }">
                            <button @click.prevent="showLogin" v-if="!userLoggedIn">
                                <div
                                    class="transition-colors duration-200 px-6 py-2 border border-transparent text-base font-bold rounded-md text-white tracking-wide bg-vote-button-bg-color text-vote-button-text-color opacity-90 hover:opacity-100 md:text-lg"
                                >VOTA</div>
                            </button>
                        </template>
                    </login-button>
                    <div v-if="voteForUserHasNotBeenStarted"
                         class="text-center text-xs uppercase opacity-75 transform scale-75">Votazioni non ancora aperte
                    </div>
                    <div v-if="voteForUserHasEnded" class="text-center text-xs uppercase opacity-75 transform scale-75">
                        Votazioni chiuse
                    </div>
                </div>
            </div>
        </div>

        <div class="sm:hidden bg-current-theme text-white shadow flex flex-col items-center p-4">
            <h3 class="font-bold uppercase">
                <a href="" @click.prevent="toggleMobileMenu" class="flex items-center space-x-2">
                    <div>Categorie</div>
                    <svg class="fill-current pb-1" width="20" height="20" viewBox="0 0 1792 1792"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z"/>
                    </svg>
                </a>
            </h3>
            <ul class="flex-col items-center pt-4" :class="{ 'hidden' :  !listVisible, 'flex' : listVisible}">
                <li v-if="(pollsActive.indexOf('best_italian_records') !== -1)">
                    <router-link
                        v-if="useVueRouter" :to="{ name: 'best_italian_records' }" class="uppercase font-bold underline"
                    >Miglior album
                    </router-link>
                    <a :href="makeRoute('/miglior-album-italiano')" v-else
                       class="uppercase font-bold underline"
                    > Miglior album
                    </a>
                </li>
                <li v-if="(pollsActive.indexOf('best_italian_artist_shows') !== -1)">
                    <router-link
                        v-if="useVueRouter" :to="{ name: 'best_italian_artist_shows' }"
                        class="uppercase font-bold underline">
                        Miglior live
                    </router-link>
                    <a :href="makeRoute('/miglior-artista-live-italiano')" v-else
                       class="uppercase font-bold underline">
                        Miglior live
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import LoginButton from '../user/LoginButton.vue'
import {mapGetters} from 'vuex'
import {getValidPolls} from "../../shared/common";
import AwardsLogo from "./AwardsLogo";
import router from "../../router";

export default {
    data() {
        return {
            useVueRouter: (process.env.VUE_APP_USE_VUE_ROUTER || 'yes') === 'yes',
            relativePath: process.env.VUE_APP_RELATIVE_PATH || '/',
            routes: router.getRoutes(),
            listVisible: false,
            awardsYear: process.env.VUE_APP_AWARDS_YEAR,
            open: this.isVoteOpenForJury,
            pollsActive: Object.keys(getValidPolls(process.env.VUE_APP_POLLS_SECTIONS || ''))
        }
    },
    watch: {
        '$route': function (to, from) {
            this.listVisible = false;
        }
    },
    computed: {
        ...mapGetters([
            'isVoteClosed',
            'voteForUserHasNotBeenStarted',
            'voteForUserHasEnded',
        ]),
    },
    methods: {
        makeRoute(route = '') {
            return (this.relativePath + route).replace(/\/\//g, "/");
        },
        toggleMobileMenu() {
            this.listVisible = !this.listVisible;
        }
    },
    components: {
        AwardsLogo,
        'login-button': LoginButton,
    },
}
</script>
