<template>
    <modal-view @close-panel="closePanel" :open="showLoginPanel" v-if="showLoginPanel">
        <div>
            <div>
                <div class="flex flex-col space-y-2">
                    <div class="flex flex-col text-white justify-center items-center" v-if="$store.state.loggingIn">
                        <h2 class="font-black text-3xl text-white">Accesso in corso</h2>
                        <svg-icon icon="loading" :width="100" :height="100" cssClass="fill-current"></svg-icon>
                    </div>
                    <div class=" flex flex-col space-y-2" v-if="!$store.state.loggingIn">
                        <div class="text-center">
                            <section v-if="!isVoteOpenForUsers">
                                <p>
                                    Le votazioni per il pubblico sono aperte <br>
                                    dal <strong class="text-success">{{ userStartDate }}</strong> al
                                    <strong class="text-success">{{ userEndDate }}</strong>.
                                </p>
                            </section>
                            <section v-if="isVoteOpenForUsers">
                                <div v-if="voteAfterLogin">
                                    <p class="big">
                                        Stai per votare<br>
                                        <span class="font-semibold text-4xl text-white">{{ candidateTitle }}</span><br>
                                        nella categoria <strong>{{ pollTitle }}</strong>
                                    </p>
                                    <p>&nbsp;</p>
                                </div>

                                <p v-if="voteAfterLogin" class="text-white text-3xl">Per completare il voto accedi!</p>

                                <p class="text-3xl text-white font-black text-center" style="margin-bottom: 10px;"
                                   v-else>
                                    Accedi! </p>

                                <div class="mx-4 flex flex-col items-center text-xs space-y-2 mt-4">

                                    <div class="text-error" v-if="$v.$error" style="padding-bottom: 20px;">
                                        <strong>E' necessario accettare entrambe le opzioni</strong>
                                    </div>

                                    <label class="flex items-start justify-center space-x-2 text-lg"
                                           :class="{'has-error': $v.loginForm.model.privacy_consent.$error}">
                                        <input
                                            type="checkbox"
                                            name="privacy_consent"
                                            value="1"
                                            class="mt-1 ring-2 bg-current-theme ring-white h-4 w-4 rounded"
                                            v-model="loginForm.model.privacy_consent"
                                            @change="$v.$dirty ? $v.loginForm.model.privacy_consent.$touch() : () => {}">
                                        <div>
                                            Accetto i
                                            <a href="https://www.rockol.it/info/privacy"
                                               target="_blank"
                                               class="underline"
                                               rel="nopener noreferrer">Termini di servizio</a>
                                        </div>
                                    </label>

                                    <label class="flex items-start justify-center space-x-2 text-lg"
                                           :class="{'has-error': $v.loginForm.model.awards_consent.$error}">
                                        <input
                                            type="checkbox"
                                            name="awards_consent"
                                            value="1"
                                            class="mt-1 ring-2 bg-current-theme ring-white h-4 w-4 rounded"
                                            v-model="loginForm.model.awards_consent"
                                            @change="$v.$dirty ? $v.loginForm.model.awards_consent.$touch() : () => {}">
                                        <div>Accetto di partecipare ai Rockol Awards e di esprimere il mio voto</div>
                                    </label>
                                </div>

                                <div class="mt-8">

                                    <ul v-if="!loginForm.showForm"
                                        class='mx-auto flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4 flex-wrap justify-center items-center'>
                                        <li>
                                            <a href=""
                                               class="rounded shadow-lg flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium text-current-theme bg-white hover:bg-indigo-50 sm:px-8"
                                               :style="{ opacity: $v.$invalid ? '1' : '1'}"
                                               @click.prevent="login('facebook')">Facebook</a>
                                        </li>
                                        <li class="hidden sm:block text-sm text-current-theme">oppure</li>
                                        <li>
                                            <a href=""
                                               class="rounded shadow-lg flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium text-current-theme bg-white hover:bg-indigo-50 sm:px-8"
                                               :style="{ opacity: $v.$invalid ? '1' : '1'}"
                                               @click.prevent="loginForm.showForm = true">MyRockol / MusicBiz</a>
                                        </li>
                                    </ul>

                                    <form v-if="loginForm.showForm" action=""
                                          @submit.prevent="loginWithUsernameAndPassword">

                                        <div class="flex flex-col items-center justify-center space-y-2 mb-2">
                                            <input placeholder="Indirizzo e-mail" type="text" class="rounded text-current-theme"
                                                   v-model="loginForm.model.prompt.username">
                                            <input placeholder="Password" type="password" class="rounded text-current-theme"
                                                   v-model="loginForm.model.prompt.password">
                                        </div>

                                        <div class="flex items-center justify-center space-x-2 mb-2">
                                            <button type="submit"
                                                    class="rounded shadow-lg flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium text-white bg-current-theme hover:bg-indigo-50 sm:px-8"
                                            >Login
                                            </button>
                                            <button type="button"
                                                    class="rounded opacity-70 shadow-lg flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8"
                                                    @click.prevent="loginForm.showForm = false">
                                                Annulla
                                            </button>
                                        </div>
                                    </form>

                                </div>

                            </section>
                        </div>

                    </div>
                </div>
            </div>

            <div class="mt-8">
                <ad-unit ad-type="ad-lazy-load" :is-lazy-loaded="true" style="margin-bottom: 16px;"></ad-unit>
            </div>
        </div>
    </modal-view>
</template>

<script>
import {
    ARTIST_CANDIDATE_TYPE,
    CREATOR_CANDIDATE_TYPE,
    pollDefinition,
    RECORD_CANDIDATE_TYPE,
    VIDEO_CANDIDATE_TYPE
} from '../../shared/common'
import SvgIcon from "../shared/SvgIcon";
import {mapGetters} from "vuex";
import format from 'date-fns/format'
import ModalView from "../page-components/ModalView";
import AdUnit from "../shared/AdUnit";

export default {
    created: function () {
        const vm = this;
        window.addEventListener('keyup', function (e) {
            if (e.key === "Escape") {
                vm.$store.commit('closeLoginPanel');
                vm.$store.commit('setVoteAfterLogin', null);
            }
        });
    },
    components: {
        AdUnit,
        SvgIcon,
        ModalView,
    },
    validations: {
        loginForm: {
            model: {
                privacy_consent: {
                    checked: (val) => {
                        return val === true;
                    }
                },
                awards_consent: {
                    checked: (val) => {
                        return val === true;
                    }
                },
            }
        }
    },
    watch: {
        showLoginPanel(show) {
            this.resetForm();
            if (show) {
                this.$root.$emit('show-login-panel');
            }
        }
    },
    data() {
        return {
            panelOpened: this.$store.state.loginPanel.show,
            loginForm: {
                isSubmitting: false,
                isSubmitted: false,
                showForm: false,
                model: {
                    prompt: {
                        username: '',
                        password: '',
                    },
                    privacy_consent: false,
                    awards_consent: false,
                }
            }
        }
    },
    computed: {
        ...mapGetters(['isVoteClosed', 'isVoteOpenForUsers', 'isVoteOpenForJury']),
        userStartDate() {
            return format(new Date(this.$store.state.app.voteDatesForUsers.start), 'DD/MM/YYYY HH:mm');
        },
        userEndDate() {
            return format(new Date(this.$store.state.app.voteDatesForUsers.end), 'DD/MM/YYYY HH:mm');
        },
        juryStartDate() {
            return format(new Date(this.$store.state.app.voteDatesForJury.start), 'DD/MM/YYYY HH:mm');
        },
        juryEndDate() {
            return format(new Date(this.$store.state.app.voteDatesForJury.end), 'DD/MM/YYYY HH:mm');
        },
        pollTitle() {
            if (this.voteAfterLogin) {
                return pollDefinition[this.voteAfterLogin.pollName].title;
            }
            return null;
        },
        candidateTitle() {
            const candidate = this.voteAfterLogin;

            switch (candidate.candidateType) {
                case ARTIST_CANDIDATE_TYPE:
                    return candidate.candidateData.artist_data.artist_name;
                case RECORD_CANDIDATE_TYPE:
                    return candidate.candidateData.artist_name + " - " + candidate.candidateData.release_title;
                case VIDEO_CANDIDATE_TYPE:
                    return candidate.candidateData.title + " - " + candidate.candidateData.artist_name;
                case CREATOR_CANDIDATE_TYPE:
                    return candidate.candidateData.creator_name;
            }

            return null;
        },
        candidateImage() {
            const candidate = this.voteAfterLogin;

            switch (candidate.candidateType) {
                case ARTIST_CANDIDATE_TYPE:
                    return candidate.candidateData.artist_data.artist_image;
                case RECORD_CANDIDATE_TYPE:
                    return candidate.candidateData.record_cover;
            }

            return null;
        },
        voteAfterLogin() {
            return this.$store.getters.voteAfterLogin;
        },
        showLoginPanel() {
            return this.$store.getters.loginPanel.show;
        }
    },
    methods: {
        resetForm() {
            this.$v.$reset();
            this.loginForm.model.privacy_consent = false;
            this.loginForm.model.awards_consent = false;
            this.loginForm.isSubmitted = false;
            this.loginForm.isSubmitting = false;
        },
        login(provider) {
            this.$v.loginForm.model.$touch();

            if (this.$v.$error) {
                this.loginForm.isSubmitted = false;
                this.loginForm.isSubmitting = false;
                return;
            }

            this.$store.dispatch('userSocialLogin', {provider});
        },
        async loginWithUsernameAndPassword() {
            this.$v.loginForm.model.$touch();

            if (this.$v.$error) {
                this.loginForm.isSubmitted = false;
                this.loginForm.isSubmitting = false;
                return;
            }

            try {
                await this.$store.dispatch('loginWithUsernameAndPassword', {
                    email: this.loginForm.model.prompt.username,
                    password: this.loginForm.model.prompt.password
                });

                this.closePanel();
            } catch (error) {
                this.$store.commit('setError', error.publicMessage || 'Impossibile effettuare l\'autenticazione');
                this.$store.commit('loggingIn', false);
                this.loginForm.isSubmitted = false;
                this.loginForm.isSubmitting = false;
            }

        },
        closePanel() {
            this.$store.commit('closeLoginPanel');
            this.$store.commit('refreshAdv');
            this.$store.commit('setVoteAfterLogin', null);
        }
    }
}
</script>

