import {getValidPolls} from '../shared/common';

import getTime from 'date-fns/get_time'
import parse from 'date-fns/parse'

const voteStartForUsers = parse(process.env.VUE_APP_USER_VOTE_START_DATE || '2018-11-16 10:00');
const voteEndForUsers = parse(process.env.VUE_APP_USER_VOTE_END_DATE || '2018-12-21 17:00');
const voteStartForJury = parse(process.env.VUE_APP_JURY_VOTE_START_DATE || '2018-11-16 10:00');
const voteEndForJury = parse(process.env.VUE_APP_JURY_VOTE_END_DATE || '2018-12-03 17:00');
const liveEventDate = parse(process.env.VUE_APP_LIVE_EVENT_DATE || '2018-12-03 17:00');
const streamingStart = parse(process.env.VUE_APP_LIVE_STREAMING_EMBED_START || null);
const streamingEnd = parse(process.env.VUE_APP_LIVE_STREAMING_EMBED_END || null);
const streamingUrl = process.env.VUE_APP_LIVE_STREAMING_EMBED_URL || null;
const currentTimestamp = process.env.VUE_APP_CURRENT_TIMESTAMP || (window.appConfiguration || {}).currentTimestamp || new Date().getTime();

const validPolls = getValidPolls(process.env.VUE_APP_POLLS_SECTIONS || '');

const userVoteStructure = Object.keys(validPolls).reduce((acc, key) => {
    acc[key] = {};
    return acc;
}, {});
const pollsStillToBeVoted = Object.keys(validPolls);

// Recupero il voto dopo il login dallo storage se supportato
// utile quando la logina avviene tramite redirect
let voteAfterLogin = null;
if (window.sessionStorage) {
    const userVoteAfterLoginInStorage = window.sessionStorage.getItem('AwardsVoteAfterLogin');
    voteAfterLogin = userVoteAfterLoginInStorage !== null ? JSON.parse(userVoteAfterLoginInStorage) : null;
}

export const state = {
    app: {
        title: 'Rockol Awards ' + process.env.VUE_APP_AWARDS_YEAR,
        host: process.env.VUE_APP_HOST || "https://www.rockol.it",
        isMobile: false,
        basePath: (process.env.VUE_APP_RELATIVE_PATH + "/").replace(/\/+$/, ''),
        useFooter: true,
        liveEventDate: getTime(new Date(liveEventDate)),
        liveStreaming: {
            'start' : streamingStart,
            'end' : streamingEnd,
            'url' : streamingUrl
        },
        voteDatesForUsers: {
            start: getTime(new Date(voteStartForUsers)),
            end: getTime(new Date(voteEndForUsers)),
        },
        voteDatesForJury: {
            start: getTime(new Date(voteStartForJury)),
            end: getTime(new Date(voteEndForJury)),
        },
        currentTimestamp,
    },
    sectionTitle: '',
    user: null,
    loginCheckInProgress: true,
    loggingIn: false,
    temporaryLogin: false,
    userLoggedIn: false,
    error: null,
    loading: false,
    currentPoll: {
        code: null,
        title: null
    },
    sharePanel: {
        show: false,
        loading: false,
        candidateType: null,
        candidateData: null,
    },
    loginPanel: {
        show: false
    },
    userPanel: {
        show: false
    },
    votePanel: {
        show: false
    },
    juryLoginScreen: {
        show: false,
        onBoarding: false,
    },
    juryOnboardScreen: {
        show: false
    },
    voteInProgress: false,
    voteAfterLogin,
    userVoteState: {
        nrVotes: 0,
        polls: validPolls,
        pollsVoted: [],
        votes: userVoteStructure,
        pollsStillToBeVoted
    },
    candidateDetailPanel: {
        show: false,
        loading: false,
        candidateType: null,
        candidateData: null,
    }
};
